<template>
  <Dialog
    v-model:visible="isVisible"
    v-bind="$attrs"
    :header="title"
    modal
    :position="position"
    :dismissable-mask="props.dismissable"
    @hide="onHide"
  >
    <slot />
  </Dialog>
</template>

<script setup lang="ts">
const emit = defineEmits(["dialog-toggle", "on-hidden"]);
const { width } = useWindowSize();
const props = defineProps({
  visible: {
    type: Boolean,
    default: () => false,
  },
  title: {
    type: String,
    default: "",
  },
  header: {
    type: Boolean,
    default: true,
  },
  dismissable: {
    type: Boolean,
    default: true,
  },
});
const isVisible = computed({
  get() {
    return props.visible;
  },
  set() {
    emit("dialog-toggle");
  },
});
const position = computed(() => {
  return width.value < 500 ? "bottom" : "center";
});
const onHide = () => {
  emit("on-hidden");
};
</script>

<style scoped></style>
